<h2 mat-dialog-title>Load Layout</h2>
<mat-dialog-content>
    <mat-form-field appearance="outline">
        <mat-label>Search</mat-label>
        <input matInput placeholder="E.g. orion">
    </mat-form-field>
    <div class="d-flex">
        <mat-checkbox [(ngModel)]="includePublic" class="my-auto">Include Public Layouts</mat-checkbox>
        <div class="ml-auto">
            <button mat-button>Search</button>
        </div> 
    </div>
    <table mat-table [dataSource]="dataSource" class="mt-2">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>
        <ng-container matColumnDef="user">
            <th mat-header-cell *matHeaderCellDef> User </th>
            <td mat-cell *matCellDef="let element"> {{element.user}} </td>
        </ng-container>
        <ng-container matColumnDef="keys">
            <th mat-header-cell *matHeaderCellDef> Keys </th>
            <td mat-cell *matCellDef="let element"> {{element.keys}} </td>
        </ng-container>
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> ID </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="highlight" (click)="onRowClicked(row)"></tr>
    
    </table>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button cdkFocusInitial (click)="onClose()">Close</button>
</mat-dialog-actions>
