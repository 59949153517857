import { DocumentReference } from '@angular/fire/firestore';

export enum LayoutType {
    reference = 1,
    private = 2,
    public = 3,
}

export interface Layout {
    uid: string;
    name: string;
    description: string;
    type: LayoutType;
    timestamp: number;
    sourceId: DocumentReference;
    data: string;
}
