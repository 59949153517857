import { Layout } from './../../models/layout.model';
import { LayoutService } from './../../services/layout.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent } from '@angular/material/paginator';


export interface SearchDialogData {
  data: any;
}

interface TableData {
  id: string;
  name: string;
  user: string;
  keys: number;
}

@Component({
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  readonly maxResults = 50;

  displayedColumns: string[] = ['name', 'user', 'keys', 'id'];
  dataSource = new MatTableDataSource<TableData>([]);
  includePublic = false;

  constructor(
    private service: LayoutService,
    public dialogRef: MatDialogRef<SearchComponent, Layout>,
    @Inject(MAT_DIALOG_DATA) public data: SearchDialogData) {
  }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.service.layoutDb.ref
      .orderBy('name')
      .limit(this.maxResults)
      .get().then(snapshot => {
        this.dataSource.data = snapshot.docs.map(item => {
          return {
            id: item.id,
            name: item.get('name'),
            user: 'user 1',
            keys: 100
          };
        });
      });
  }

  onRowClicked(item: TableData): void {
    this.service.layoutDb.doc(item.id).get().subscribe(
      result => {
        const jsonData = result.data().data;
        this.dialogRef.close(JSON.parse(jsonData));
      });
  }

  onClose() {
    this.dialogRef.close();
  }
}
