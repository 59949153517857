import { Component, OnInit, HostListener, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { IKeyLayer, IKey } from './keyboard-data';

@Component({
    selector: 'app-keylayout',
    template: `
    <app-key *ngFor="let key of keyData?.keys; let i = index"
    [isInputMode]="isInputMode" [unit]="unit" [keyData]="key"
    [needFocus]="i === focusedKeyIndex" (focused)="onKeyFocused(i)" (keyDataChanged)="keyDataChanged(i)"></app-key>
    `,
    styles: [`
    :host {
        display: block;
        width: 100%;
        height: 300px;
        position: relative;
    }
    `]
})
export class KeyLayoutComponent implements OnInit {

    @Input() isInputMode = true;

    @Input() keyData: IKeyLayer;

    @Input() inputKey: Observable<IKey>;

    private _focused = false;
    @Input() set focused(focus: boolean) {

        this._focused = focus;

        if (focus === true) {
            this.focusedKeyIndex = 0;
        }
    }

    @Output() outputKey = new EventEmitter<IKey>();

    public focusedKeyIndex = 0;

    get unit(): number {
        return 35;
    }

    private _innerWidth: number;

    @HostListener('window:resize', ['$event'])
    public onResize(event) {
        this._innerWidth = event.target.innerWidth;
    }

    constructor(private el: ElementRef) {
    }

    ngOnInit(): void {
        this._innerWidth = this.el.nativeElement.offsetWidth;

        if (this.isInputMode === true) {
            this.inputKey.subscribe(key => {

                if (this._focused === false) {
                    return;
                }
                const focusedKey = this.keyData.keys[this.focusedKeyIndex];
                focusedKey.text = key.text;
                focusedKey.scanCode = key.scanCode;
                this._focusNextKey();
            });
        }
    }

    public keyDataChanged(index: number): void {

        if (this.isInputMode === false) {

            this.outputKey.emit(this.keyData.keys[index]);

            return;

        } else {

            this.focusedKeyIndex = index;
            this._focusNextKey();

        }
    }

    public onKeyFocused(index: number): void {

        this.focusedKeyIndex = index;

    }

    private _focusNextKey() {

        this.focusedKeyIndex++;

        if (this.focusedKeyIndex >= this.keyData.keys.length) {
            this.focusedKeyIndex = 0;
        }
    }

}
