import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IKey, IKeyboardStorageFileData, IMacroData } from './../../keyboard/keyboard-data';

import { map, retry } from 'rxjs/operators';
import { KeyCodeCoverterService } from './key-code-converter.service';

@Injectable({
  providedIn: 'root'
})
export class BinaryService {

  readonly startAddress = 0x8002000;
  readonly startAddressSignature: number[] = [0X00, 0XFF, 0XAA, 0X55, 0XFF, 0X11, 0XFF, 0X00];
  readonly sectionSize = 256;
  readonly layoutSectionIndex = 2;
  readonly macroSectionIndex = 8;
  readonly macroSize = 32;
  readonly totalMacroCountInSection = 4;

  constructor(
    private keyCodeConverterService: KeyCodeCoverterService,
    private http: HttpClient,
  ) { }

  downloadBinaryFromLayout(layout: IKeyboardStorageFileData) {
    this.load(layout).subscribe(data => {
      const a = document.createElement('a');
      const blob = new Blob([data], { type: 'octet/stream' });
      a.href = window.URL.createObjectURL(blob);
      a.download = 'firmware.bin';
      a.click();
    });
  }


  private load(keyboardData: IKeyboardStorageFileData) {
    return this.http.get('assets/firmware.bin', { responseType: 'arraybuffer' }).pipe(
      map((data: any) => {
        const dataArray = new Uint8Array(data);
        this.updateBinaryData(keyboardData, dataArray);
        return dataArray;
      })
    );
  }

  private updateBinaryData(keyboardData: IKeyboardStorageFileData, dataArray: Uint8Array) {

    const configurationDataStartAddress = this.findFirstDataOffset(dataArray);

    keyboardData.keyLayers.forEach((layout, index) => {

      const dataAddress = this.getAddressByIndex(configurationDataStartAddress, index);
      this.cleanLayout(dataArray, dataAddress);

      layout.keys.forEach(key => {
        this.updateKey(key, dataAddress, dataArray);
      });
    });

    keyboardData.macro.forEach((macro, index) => {
      const address = this.getMacroAddress(configurationDataStartAddress, index);
      this.convertMacroToUint8Array(macro, address, dataArray);
    })
  }

  private convertMacroToUint8Array(macro: IMacroData, address: number, dataArray: Uint8Array) {

    for (let index = 0; index < this.macroSize; index++) {

      let modifier: number = 0;
      let keyCode: number = 0;

      if (macro.inputData.length > index) {
        modifier = this.extractModifierByteForMacroInputData(macro.inputData[index].modifiers);
        keyCode = this.keyCodeConverterService.convert(macro.inputData[index].key);
      }
      const dataIndex = address + index * 2;
      dataArray[dataIndex] = modifier;
      dataArray[dataIndex + 1] = keyCode;
    }
  }

  private extractModifierByteForMacroInputData(modifiers: string[]): number {
    const modifierMapper = {
      ControlLeft: 0x01,
      ShiftLeft: 0x02,
      AltLeft: 0x04,
      MetaLeft: 0x08,
      ControlRight: 0x10,
      ShiftRight: 0x20,
      AltRight: 0x40,
      MetaRight: 0x80
    };

    let result = 0x00;

    modifiers.forEach(modifier => {
      // tslint:disable-next-line:no-bitwise
      result = result | modifierMapper[modifier];
    });

    return result;
  }

  private cleanLayout(dataArray: Uint8Array, startAddress: number) {
    for (let index = 0; index < this.sectionSize; index++) {
      dataArray[startAddress + index] = 0x00;
    }
  }

  private updateKey(key: IKey, dataAddress: number, firmwareMemoryMap: Uint8Array) {
    if (key.scanCode !== undefined && key.scanCode >= 0) {
      firmwareMemoryMap[dataAddress + key.mappingIndex] = key.scanCode;
    } else {
      // TODO: log the error properly
      console.log(`${key.text} does not exist.`);
    }
  }

  private findFirstDataOffset(dataArray: Uint8Array): number {

    for (let offset = 0; offset + this.startAddressSignature.length < dataArray.length; offset++) {

      let allMatch = true;

      for (let index = 0; index < this.startAddressSignature.length; index++) {
        if (dataArray[offset + index] !== this.startAddressSignature[index]) {
          allMatch = false;
          break;
        }
      }

      if (allMatch) {
        return offset;
      }
    }

    return null;
  }

  private getMacroAddress(configurationDataStartAddress: number, index: number) {
    const sectionOffset = Math.floor(index / this.totalMacroCountInSection) + this.macroSectionIndex;
    const sectionAddress = configurationDataStartAddress + (sectionOffset * this.sectionSize);
    return sectionAddress + ((index % this.totalMacroCountInSection) * this.macroSize * 2);
  }

  private getAddressByIndex(configurationDataStartAddress: number, index: number) {
    const sectionIndex = index + this.layoutSectionIndex;
    return configurationDataStartAddress + (this.sectionSize * sectionIndex);
  }

}
