import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from './../material.module';
import { KeyLayoutComponent } from './key-layout.component';
import { KeyMapParserService } from './key-map-parser.service';
import { KeyMapperComponent } from './key-mapper.component';
import { KeyComponent } from './key.component';
import { MacroTableComponent } from './macro-table/macro-table.component';

@NgModule({
    declarations: [
        KeyComponent,
        KeyLayoutComponent,
        KeyMapperComponent,
        MacroTableComponent,
    ],
    imports: [
        FormsModule,
        CommonModule,
        MaterialModule
    ],
    exports: [KeyMapperComponent],
    providers: [KeyMapParserService]
})
export class KeyboardModule { }
