<mat-toolbar>
  <button mat-icon-button color="accent" [matMenuTriggerFor]="menu">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-form-field class="example-form-field">
    <!-- <mat-label>Clearable input</mat-label> -->
    <input matInput type="text" [(ngModel)]="title">
    <!-- <button mat-button *ngIf="title" matSuffix mat-icon-button aria-label="Clear" (click)="value=''">
    <mat-icon>close</mat-icon>
  </button> -->
  </mat-form-field>
  <span class="spacer"></span>
  <ng-container *ngIf="user">
    <button mat-button (click)="onLoad()">Load</button>
    <button mat-button (click)="onSave()">Save</button>
  </ng-container>
  <button *ngIf="user; else loginTemplate" mat-raised-button color="primary" (click)="logout()">
    {{user.displayName}}
  </button>
  <ng-template #loginTemplate>
    <!-- <button mat-button (click)="login()" [matTooltip]="'Login'">
      Sign in
    </button> -->
  </ng-template>
</mat-toolbar>

<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="fileInput.click()">
    <input #fileInput hidden="true" type="file" onclick="this.value=null" accept=".tcm.json" (change)="onFileImport($event)" />
    <mat-icon>file_upload</mat-icon>
    <span>Upload Layout</span>
  </button>
  <a mat-menu-item (click)="onLayoutDownload()">
  <!-- <a *ngIf="downloadUrl" mat-menu-item [href]="downloadUrl" download="keyboard_layout.json"> -->
    <mat-icon>file_download</mat-icon>
    <span>Download Layout</span>
  </a>
  <mat-divider></mat-divider>
  <button mat-menu-item (click)="onFirmwareDownload()">
  <!-- <a *ngIf="firmwareUrl" mat-menu-item [href]="firmwareUrl" download="firmware.bin"> -->
    <mat-icon>file_download</mat-icon>
    <span>Download Firmware</span>
  </button>
</mat-menu>

<app-key-mapper [sourceKeyboard]="sourceKeyboardData" [targetKeyboard]="targetKeyboardData"></app-key-mapper>

<router-outlet></router-outlet>
