import { Injectable, SecurityContext } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, DocumentReference } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { from, Observable, pipe } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { IKeyboardStorageFileData } from '../keyboard/keyboard-data';
import { Layout } from './../models/layout.model';

@Injectable({ providedIn: 'root' })
export class LayoutService {

    readonly layoutCollectionName = 'layouts';
    readonly layoutDownloadFileName = 'keyboard_layout.tcm.json';

    constructor(
        private snackBar: MatSnackBar,
        private firestore: AngularFirestore,
        private domSanitizer: DomSanitizer,
    ) { }

    get layoutDb() {
        return this.firestore.collection<Layout>(this.layoutCollectionName);
    }

    downloadLayout(layout: IKeyboardStorageFileData) {
        const json = JSON.stringify(layout);
        const safeUrl = this.domSanitizer.bypassSecurityTrustUrl('data:text/json;charset=UTF-8,' + encodeURIComponent(json));
        const a = document.createElement('a');
        a.href = this.domSanitizer.sanitize(SecurityContext.URL, safeUrl);
        a.download = this.layoutDownloadFileName;
        a.click();
    }

    updateLayout(layout: Layout, reference: string) {
        return from(this.layoutDb.doc(reference).set(layout)).pipe(
            map(() => reference)
        );
    }

    addNewLayout(layout: Layout) {
        return from(this.layoutDb.add(layout)).pipe(
            map(referece => referece.id)
        );
    }

    private saveResultHandlingPipe = () => {
        return pipe(
            tap(() => this.snackBar.open('Your layout has been saved successfully.', 'close')),
            catchError(err => {
                console.error('Save failed.' + err, 'close');
                return err;
            })
        );
    };

    // private _saveToReference() {
    //     const testData: Layout = {
    //         uid: this.user.uid,
    //         name: this.title,
    //         description: '',
    //         type: LayoutType.private,
    //         timestamp: Date.now(),
    //         sourceId: null,
    //         data: JSON.stringify(this.targetKeyboardData)
    //     };

    //     this.service.layoutDb.doc('reference').set(testData)
    //         .then(() => {
    //             console.log('test');
    //         })
    //         .catch((error) => {
    //             console.error('test');
    //         });
    // }

    // private _saveToTarget() {
    //     const testData: Layout = {
    //         uid: this.user.uid,
    //         name: this.title,
    //         description: '',
    //         type: LayoutType.private,
    //         timestamp: Date.now(),
    //         sourceId: null,
    //         data: JSON.stringify(this.targetKeyboardData)
    //     };

    //     this.service.layoutDb.doc('target').set(testData)
    //         .then(() => {
    //             console.log('test');
    //         })
    //         .catch((error) => {
    //             console.error('test');
    //         });
    // }
}
