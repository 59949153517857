import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import firebase from 'firebase/app';
import { Observable } from 'rxjs';
import { IKeyboardStorageFileData } from './keyboard/keyboard-data';
import { SearchComponent } from './modals/search/search.component';
import { Layout, LayoutType } from './models/layout.model';
import { BinaryService } from './services/binary/binary.service';
import { LayoutService } from './services/layout.service';

import referenceLayout from '../assets/reference.json';
import targetLayout from '../assets/target.json';

export interface Item { name: string; }


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public user: firebase.User;
  public targetKeyboardData: IKeyboardStorageFileData;
  public sourceKeyboardData: IKeyboardStorageFileData;
  public title = 'New Keyboard Layout';
  public item: Observable<Item>;

  private itemDoc: AngularFirestoreDocument<Item>;

  constructor(
    private service: LayoutService,
    private dialog: MatDialog,
    private firestore: AngularFirestore,
    private auth: AngularFireAuth,
    private domSanitizer: DomSanitizer,
    private binaryService: BinaryService,
  ) {

    this.itemDoc = firestore.doc<Item>('items/1');
    this.item = this.itemDoc.valueChanges();

    this.itemDoc.set({ name: 'test' });

    this.item.subscribe(data => {
      console.log(data);
    });


    // this.service.layoutDb.doc('reference').get().subscribe(result => {
    //   const layout = result.data();
    //   this.sourceKeyboardData = JSON.parse(layout.data);
    // });

    // this.service.layoutDb.doc('target').get().subscribe(result => {
    //   const layout = result.data();
    //   this.targetKeyboardData = JSON.parse(layout.data);
    // });

    auth.onAuthStateChanged(user => {
      console.log(user);
      this.user = user;
    });

    // this.comments$ = afs.collectionGroup('Comments', ref => ref.where('user', '==', userId))
    //                 .valueChanges({ idField: 'docId' });

    // firestore.collectionGroup('lasyout').valueChanges().subscribe(data => {
    //   console.log(data);
    // });

    firestore.collection('layouts').get().subscribe((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, ' => ', doc.data());
      });
    });

    // firestore.collection('layous').get().subscribe(data => {
    //   console.log(data);
    // });
  }

  ngOnInit(): void {
    this.sourceKeyboardData = referenceLayout;
    this.targetKeyboardData = targetLayout;
  }

  login(): void {
    this.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
  }

  logout(): void {
    this.auth.signOut();
  }

  onSave(): void {

    // this._saveToReference();
    // this._saveToTarget();

    const testData: Layout = {
      uid: this.user.uid,
      name: this.title,
      description: '',
      type: LayoutType.private,
      timestamp: Date.now(),
      sourceId: null,
      data: JSON.stringify(this.targetKeyboardData)
    };

    this.service.layoutDb.add(testData)
      .then(() => {
        console.log('test');
      })
      .catch((error) => {
        console.error('test');
      });
  }

  onFileImport(fileEvent: any): void {
    if (fileEvent?.target?.files?.length) {
      const fileReader = new FileReader();
      fileReader.onloadend = error => {
        const data = JSON.parse(fileReader.result.toString());
        this.targetKeyboardData = data;
      };
      fileReader.readAsText(fileEvent.target.files[0]);
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(SearchComponent, {
      width: '800px',
      data: { data: null }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.targetKeyboardData = result;
      }
    });
  }

  onLoad(): void {
    this.openDialog();
  }

  onLayoutDownload() {
    this.service.downloadLayout(this.targetKeyboardData);
  }

  onFirmwareDownload() {
    this.binaryService.downloadBinaryFromLayout(this.targetKeyboardData);
  }

  private _saveToReference() {
    const testData: Layout = {
      uid: this.user.uid,
      name: this.title,
      description: '',
      type: LayoutType.private,
      timestamp: Date.now(),
      sourceId: null,
      data: JSON.stringify(this.targetKeyboardData)
    };

    this.service.layoutDb.doc('reference').set(testData)
      .then(() => {
        console.log('test');
      })
      .catch((error) => {
        console.error('test');
      });
  }

  private _saveToTarget() {
    const testData: Layout = {
      uid: this.user.uid,
      name: this.title,
      description: '',
      type: LayoutType.private,
      timestamp: Date.now(),
      sourceId: null,
      data: JSON.stringify(this.targetKeyboardData)
    };

    this.service.layoutDb.doc('target').set(testData)
      .then(() => {
        console.log('test');
      })
      .catch((error) => {
        console.error('test');
      });
  }
}
