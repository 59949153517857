import { Injectable } from '@angular/core';
import { IKey, IKeyLayer } from './keyboard-data';

class Position {

    public x: number;
    public y: number;

    public constructor(x: number, y: number) {
        this.x = x;
        this.y = y;
    }
}

@Injectable()
export class KeyMapParserService {

    public calculateRelativePostionToAbsolutePostion(originalKeyboardData: IKeyLayer): IKeyLayer {

        if (originalKeyboardData.keys.length < 1) {
            throw new Error('Invalid KeyboardData.');
        }

        const originalKeys = originalKeyboardData.keys;
        const resultKeys = new Array<IKey>();
        let nextPosition: Position = new Position(-1, -1);

        for (let index = 0; index < originalKeys.length; index++) {


            let newKey = Object.assign({}, originalKeys[index]);

            nextPosition.x += newKey.space;

            if (newKey.x === -1 || newKey.y === -1) {
                newKey = Object.assign(newKey, nextPosition);
            } else {
                nextPosition = new Position(newKey.x, newKey.y);
            }

            resultKeys.push(newKey);
            nextPosition.x += newKey.width;
        }

        return { keys: resultKeys };
    }
}



