import {
    Component,
    ElementRef, EventEmitter, HostBinding, Input,
    OnChanges, OnInit, Output,
    SimpleChanges, ViewChild
} from '@angular/core';
import { IKey } from './keyboard-data';

@Component({
    selector: 'app-key',
    templateUrl: './key.component.html',
    styles: [`
    :host {
        position: absolute;
        display: flex;
    }
    button {
        border-radius: 3px;
        width: 100%;
        height: 100%;
        font-size: 9px;
    }
    `]
})
export class KeyComponent implements OnInit, OnChanges {

    @ViewChild('keyButton') buttonEl: ElementRef;

    @HostBinding('style.top.px')
    public get top(): number {
        return this.keyData.y * this.unit;
    }
    @HostBinding('style.left.px')
    public get left(): number {
        return this.keyData.x *  this.unit;
    }
    @HostBinding('style.height.px')
    public get height(): number {
        return this.keyData.height * this.unit;
    }
    @HostBinding('style.width.px')
    public get width(): number {
        return this.keyData.width * this.unit;
    }

    @Input() index = -1;

    @Input() unit = 1;

    @Input() keyData: IKey;
     
    @Input() needFocus = false;

    @Input() isInputMode = false;

    @Output() keyDataChanged = new EventEmitter<string>();

    @Output() focused = new EventEmitter<void>();

    public get displayText(): string {
        let parts = this.keyData.text.split(/(?=[A-Z])/);
        let lastElement = parts.pop();
        let index = lastElement.search(/\d/);
        parts.push(lastElement.substring(0, index));
        parts.push(lastElement.substring(index));
        return parts.join(' ');
    }

    constructor() { }

    ngOnInit(): void {

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.needFocus != null && changes.needFocus.currentValue === true) {
            if (this.buttonEl) {
                this.buttonEl.nativeElement.focus();
            }
        }
    }

    public onKeyDown(event: KeyboardEvent) {

        if (this.isInputMode === false) {
            return;
        }

        this.keyData.text = event.code;
        this.keyDataChanged.emit(event.code);
        event.stopPropagation();
        event.preventDefault();
    }

    public onClick(event: MouseEvent) {

        if (this.isInputMode === true) {

            this.focused.emit();
            return;

        } else {

            this.keyDataChanged.emit(this.keyData.text);

        }
    }
}
