<mat-card>
  <mat-card-content>
    <mat-tab-group [(selectedIndex)]="selectedTabIndex">
      <ng-container *ngIf="targetKeyboard">
        <mat-tab *ngFor="let keyLayer of targetKeyboard.keyLayers; let i = index" label="LAYER {{i + 1}}">
          <app-keylayout [keyData]="keyLayer" [isInputMode]="true" [inputKey]="keyInput" [focused]="i === selectedTabIndex"></app-keylayout>
        </mat-tab>
        <mat-tab [label]="'MACRO'">
          <app-macro-table [macroData]="targetKeyboard.macro"></app-macro-table>
        </mat-tab>
      </ng-container>
    </mat-tab-group>
  </mat-card-content>
</mat-card>
<mat-accordion>
  <mat-expansion-panel [expanded]="true" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Reference Key Layout
      </mat-panel-title>
      <mat-panel-description>
        {{panelOpenState? 'Click to Close' : 'Click to Open'}}
      </mat-panel-description>
    </mat-expansion-panel-header>
    <app-keylayout *ngIf="sourceKeyboard" [keyData]="sourceKeyboard.keyLayers[0]" [isInputMode]="false" (outputKey)="onKeyInput($event)"></app-keylayout>
  </mat-expansion-panel>
</mat-accordion>