import { Component, Input, OnInit } from '@angular/core';
import { IMacroData, IMacroKeyData } from 'src/app/keyboard/keyboard-data';

@Component({
  selector: 'app-macro-table',
  templateUrl: './macro-table.component.html',
  styleUrls: ['./macro-table.component.scss']
})
export class MacroTableComponent implements OnInit {

    readonly modifierKeys = ['shift', 'control', 'alt', 'gui'];

    @Input() public macroData: IMacroData[];

    public inputValue = '';
    public displayedColumns = ['position', 'macroData'];
    public constructor() { }

    private currentModifilers = new Set<string>();

    ngOnInit(): void { }

    public resetInput() {
        this.inputValue = 'a';
    }

    public formatKey(keyData: IMacroKeyData): string {
        let result = '';
        keyData.modifiers.forEach(key => {
            result += key + '+';
        });
        result += keyData.key.toUpperCase();
        return result;
    }

    public onKeyUp(event: KeyboardEvent, macroRow: IMacroData) {
        const keyCode = event.code;
        event.stopPropagation();
        event.preventDefault();

        if (this.isModifierCode((keyCode))) {
            this.currentModifilers.delete(keyCode);
        } else {
            macroRow.inputData.push({ key: keyCode, modifiers: Array.from(this.currentModifilers) });
            this.inputValue = '';
        }
    }

    public onKeyDown(event: KeyboardEvent) {
        const keyCode = event.code;
        event.stopPropagation();
        event.preventDefault();

        if (this.isModifierCode(keyCode)) {
            this.currentModifilers.add(keyCode);
        }
    }

    public onDeleteClick(macroData: IMacroData): void {
        macroData.inputData = [];
    }

    private isModifierCode(key: string) {
        return this.modifierKeys.some(modifier => key.toLowerCase().includes(modifier));
    }
}