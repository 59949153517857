import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { IKey, IKeyboardStorageFileData } from './keyboard-data';

@Component({
    selector: 'app-key-mapper',
    templateUrl: './key-mapper.component.html',
    styleUrls: ['./key-mapper.component.scss']
})

export class KeyMapperComponent implements OnInit {

    @Input() public targetKeyboard: IKeyboardStorageFileData;
    @Input() public sourceKeyboard: IKeyboardStorageFileData;

    public keyInput = new Subject<IKey>();

    public panelOpenState = false;

    public selectedTabIndex = 0;

    constructor() {}

    ngOnInit(): void {}

    public onKeyInput(newKey: IKey): void {
        this.keyInput.next(newKey);
    }
}
