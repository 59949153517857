<table mat-table [dataSource]="macroData" class="macro-table">
    <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef>Macro Key</th>
        <td mat-cell *matCellDef="let row"> {{row.order}} </td>
    </ng-container>
    <ng-container matColumnDef="macroData">
        <th mat-header-cell *matHeaderCellDef>Macro Data</th>
        <td mat-cell class="macro-data" *matCellDef="let row">
            <mat-form-field class="macro-data-input">
                <div class="macro-data-input">
                    <mat-chip-list #chipList>
                        <mat-chip *ngFor="let keyData of row.inputData">
                            {{formatKey(keyData)}}
                        </mat-chip>
                        <input type="text" placeholder="Macro Key {{row.order}}" [matChipInputFor]="chipList"
                            [(ngModel)]="inputValue" (keyup)="onKeyUp($event, row)"
                            (keydown)="onKeyDown($event)" />
                    </mat-chip-list>
                </div>
            </mat-form-field>
            <div class="delete-button">
                <button mat-raised-button [disabled]="row.inputData.length === 0"
                    (click)="onDeleteClick(row)">Delete</button>
            </div>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>