import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class KeyCodeCoverterService {

    private mapper = {
        KeyNONE: 0x00
        , KeyERR_OVF: 0x01

        , KeyA: 0x04
        , KeyB: 0x05
        , KeyC: 0x06
        , KeyD: 0x07
        , KeyE: 0x08
        , KeyF: 0x09
        , KeyG: 0x0a
        , KeyH: 0x0b
        , KeyI: 0x0c
        , KeyJ: 0x0d
        , KeyK: 0x0e
        , KeyL: 0x0f
        , KeyM: 0x10
        , KeyN: 0x11
        , KeyO: 0x12
        , KeyP: 0x13
        , KeyQ: 0x14
        , KeyR: 0x15
        , KeyS: 0x16
        , KeyT: 0x17
        , KeyU: 0x18
        , KeyV: 0x19
        , KeyW: 0x1a
        , KeyX: 0x1b
        , KeyY: 0x1c
        , KeyZ: 0x1d

        , Digit1: 0x1e
        , Digit2: 0x1f
        , Digit3: 0x20
        , Digit4: 0x21
        , Digit5: 0x22
        , Digit6: 0x23
        , Digit7: 0x24
        , Digit8: 0x25
        , Digit9: 0x26
        , Digit0: 0x27

        , Enter: 0x28
        , Escape: 0x29
        , Backspace: 0x2a
        , Tab: 0x2b
        , Space: 0x2c
        , Minus: 0x2d
        , Equal: 0x2e
        , BracketLeft: 0x2f
        , BracketRight: 0x30
        , Backslash: 0x31
        , Europe1: 0x32
        , Semicolon: 0x33
        , Quote: 0x34
        , Backquote: 0x35
        , Comma: 0x36
        , Period: 0x37
        , Slash: 0x38
        , CapsLock: 0x39

        , F1: 0x3a
        , F2: 0x3b
        , F3: 0x3c
        , F4: 0x3d
        , F5: 0x3e
        , F6: 0x3f
        , F7: 0x40
        , F8: 0x41
        , F9: 0x42
        , F10: 0x43
        , F11: 0x44
        , F12: 0x45

        , PrintScreen: 0x46
        , ScrollLock: 0x47
        , Pause: 0x48
        , Insert: 0x49
        , Home: 0x4a
        , PageUp: 0x4b
        , Delete: 0x4c
        , End: 0x4d
        , PageDown: 0x4e
        , ArrowRight: 0x4f
        , ArrowLeft: 0x50
        , ArrowDown: 0x51
        , ArrowUp: 0x52

        , NumLock: 0x53
        , NumpadDivide: 0x54
        , NumpadMultiply: 0x55
        , NumpadSubtract: 0x56
        , NumpadAdd: 0x57
        , NumpadEnter: 0x58
        , Numpad1: 0x59
        , Numpad2: 0x5a
        , Numpad3: 0x5b
        , Numpad4: 0x5c
        , Numpad5: 0x5d
        , Numpad6: 0x5e
        , Numpad7: 0x5f
        , Numpad8: 0x60
        , Numpad9: 0x61
        , Numpad0: 0x62
        , NumpadComma: 0x63

        , Europe2: 0x64
        , App: 0x65
        , Power: 0x66
        , NumpadEqual: 0x67

        , F13: 0x68
        , F14: 0x69
        , F15: 0x6a
        , F16: 0x6b
        , F17: 0x6c
        , F18: 0x6d
        , F19: 0x6e
        , F20: 0x6f
        , F21: 0x70
        , F22: 0x71
        , F23: 0x72
        , F24: 0x73

        , Excute: 0x74
        , Help: 0x75
        , Menu: 0x76
        , Select: 0x77
        , Stop: 0x78
        , Again: 0x79
        , Undo: 0x7a
        , Cut: 0x7b
        , Copy: 0x7c
        , Paste: 0x7d
        , Find: 0x7e
        , Mute: 0x7f
        , VolumnUp: 0x80
        , VolumnDown: 0x81
        //: 0x82  Keyboard Locking Caps Lock
        //: 0x83  Keyboard Locking Num Lock
        //: 0x84  Keyboard Locking Scroll Lock
        , NumpadDecimal: 0x85
        //: 0x86  Keypad Equal Sign
        , KeyRO: 0x87
        , KeyKATAKANAHIRAGANA: 0x88
        , KeyYEN: 0x89
        , KeyHENKAN: 0x8a
        , KeyMUHENKAN: 0x8b
        , KeyKPJPCOMMA: 0x8c
        //: 0x8d  Keyboard International7
        //: 0x8e  Keyboard International8
        //: 0x8f  Keyboard International9
        , KeyHANGEUL: 0x90
        , KeyHANJA: 0x91
        , KeyKATAKANA: 0x92
        , KeyHIRAGANA: 0x93
        , KeyZENKAKUHANKAKU: 0x94
        //: 0x95  Keyboard LANG6
        //: 0x96  Keyboard LANG7
        //: 0x97  Keyboard LANG8
        //: 0x98  Keyboard LANG9
        //: 0x99  Keyboard Alternate Erase
        //: 0x9a  Keyboard SysReq/Attention
        //: 0x9b  Keyboard Cancel
        //: 0x9c  Keyboard Clear
        //: 0x9d  Keyboard Prior
        //: 0x9e  Keyboard Return
        //: 0x9f  Keyboard Separator
        //: 0xa0  Keyboard Out
        //: 0xa1  Keyboard Oper
        //: 0xa2  Keyboard Clear/Again
        //: 0xa3  Keyboard CrSel/Props
        //: 0xa4  Keyboard ExSel

        //: 0xb0  Keypad 00
        //: 0xb1  Keypad 000
        //: 0xb2  Thousands Separator
        //: 0xb3  Decimal Separator
        //: 0xb4  Currency Unit
        //: 0xb5  Currency Sub-unit
        , KeyKPLEFTPAREN: 0xb6 // Keypad (
        , KeyKPRIGHTPAREN: 0xb7 // Keypad )
        //: 0xb8  Keypad {
        //: 0xb9  Keypad }
        //: 0xba  Keypad Tab
        //: 0xbb  Keypad Backspace
        //: 0xbc  Keypad A
        //: 0xbd  Keypad B
        //: 0xbe  Keypad C
        //: 0xbf  Keypad D
        //: 0xc0  Keypad E
        //: 0xc1  Keypad F
        //: 0xc2  Keypad XOR
        //: 0xc3  Keypad ^
        //: 0xc4  Keypad %
        //: 0xc5  Keypad <
        //: 0xc6  Keypad >
        //: 0xc7  Keypad &
        //: 0xc8  Keypad &&
        //: 0xc9  Keypad |
        //: 0xca  Keypad ||
        //: 0xcb  Keypad :
        //: 0xcc  Keypad #
        //: 0xcd  Keypad Space
        //: 0xce  Keypad @
        //: 0xcf  Keypad !
        //: 0xd0  Keypad Memory Store
        //: 0xd1  Keypad Memory Recall
        //: 0xd2  Keypad Memory Clear
        //: 0xd3  Keypad Memory Add
        //: 0xd4  Keypad Memory Subtract
        //: 0xd5  Keypad Memory Multiply
        //: 0xd6  Keypad Memory Divide
        //: 0xd7  Keypad +/-
        //: 0xd8  Keypad Clear
        //: 0xd9  Keypad Clear Entry
        //: 0xda  Keypad Binary
        //: 0xdb  Keypad Octal
        //: 0xdc  Keypad Decimal
        //: 0xdd  Keypad Hexadecimal

        , MediaPlayPause: 0xe8
        , MediaStopCd: 0xe9
        , MediaPrevious: 0xea
        , MediaNext: 0xeb
        , MediaEject: 0xec
        , MediaVolumnUp: 0xed
        , MediaVoulmnDown: 0xee
        , MediaMute: 0xef
        , MediaWww: 0xf0
        , MediaBack: 0xf1
        , MediaForward: 0xf2
        , MediaStop: 0xf3
        , MediaFind: 0xf4
        , MediaScrollUp: 0xf5
        , MediaScrollDown: 0xf6
        , MediaEdit: 0xf7
        , MediaSleep: 0xf8
        , MediaCoffee: 0xf9
        , MediaRefresh: 0xfa
        , MediaCalc: 0xfb

        , ControlLeft: 200
        , ShiftLeft: 201
        , AltLeft: 202
        , MetaLeft: 203
        , ControlRight: 204
        , ShiftRight: 205
        , AltRight: 206
        , MetaRight: 207

        , Macro1: 150
        , Macro2: 151
        , Macro3: 152
        , Macro4: 153
        , Macro5: 154
        , Macro6: 155
        , Macro7: 156
        , Macro8: 157
        , Macro9: 158
        , Macro10: 159

        , LedFront1: 162
        , LedFront2: 163
        , LedFront3: 164
        , LedFront4: 165
        , LedFront5: 166
        , LedFront6: 167
        , LedFront7: 168
        , LedFront8: 169
        , LedFront9: 170
        , LedFront10: 171

        , LedBack1: 172
        , LedBack2: 173
        , LedBack3: 174
        , LedBack4: 175
        , LedBack5: 176
        , LedBack6: 177
        , LedBack7: 178
        , LedBack8: 179
        , LedBack9: 180
        , LedBack10: 181

        , Layer1: 210
        , Layer2: 211
        , Layer3: 212
        , Layer4: 213
        , Layer5: 214
        , LayerToggle1: 215
        , LayerToggle2: 216
        , LayerToggle3: 217
        , LayerToggle4: 218
        , LayerToggle5: 219
    };

    public convert(key: string): number {

        if (!(key in this.mapper)) {

            return 0;

        }

        return this.mapper[key];
    }
}